<template>
  <b-row>
    <b-col md="8"> 
        <b-card>
          <tabelData :items = 'items' @editsData = 'editData'></tabelData>
        </b-card>  
    </b-col>
    <b-col md="4">
       
        <b-card> 
          <form-add :dataInput = dataEdit @loadData = laod_data></form-add>
        </b-card> 
    </b-col>
  </b-row>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base';
  import tabelData from './tabel.vue'
  import formAdd from './formAdd.vue'
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  } from 'bootstrap-vue'

  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      tabelData,
      BCard,
      BCardText,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      formAdd,

    },
    data() {
      return { 
        items:[],
        dataEdit : {}
      }
    },
    computed: { 
    },
    mounted() { 
      this.laod_data()
    },
    mixins:[Base],
    methods: {
      async laod_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/master/bidang/load_data',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;
                        self.dataEdit = {}

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            editData( value){ 
              this.dataEdit  = value; 
            }
    },
  }
</script>