 <template>
     <div>
         <b-form-group label="Nama Bidang" label-for="basicInput">
             <b-form-input id="basicInput" placeholder="Nama Bidang" v-model="inputData.nama_bidang" />
         </b-form-group>
         <b-form-group label="Keterangan" label-for="basicInput">
             <b-form-textarea id="textarea-default" placeholder="Keti keterangan jikadiperlukan" rows="3"
                 v-model="inputData.ket" />
         </b-form-group>



         <b-button   block variant="outline-primary"
             @click="simpanData(inputData)" v-if="inputData.id_bidang < 1 || inputData.id_bidang  == null">
             <i class="fa fa-save"></i> Simpan
         </b-button>
         <b-button   block variant="primary" @click="updataData(inputData)"
             v-if="inputData.id_bidang > 0">
             <i class="fa fa-save"></i> Update
         </b-button>
         <b-button   block variant="outline-warning" @click="clearData()">
             <feather-icon icon="RefreshCwIcon" /> Batal
         </b-button>


     </div>
 </template>

 <script>
     import axios from '@/config/Axios'
     import base from '@/config/Mixins_base'
     import {
         BButton,
         BFormInput,
         BRow,
         BCol,
         BFormGroup,
         BFormTextarea
     } from 'bootstrap-vue'

     import Ripple from 'vue-ripple-directive'
     export default {
         components: {
             BButton,
             BFormInput,
             BFormGroup,
             BRow,
             BCol,
             BFormTextarea,
         },
         data() {
             return {}
         },
         mixins: [base],
         emits: ["loadData"],
         props: {
             dataInput: {}
         },
         computed: {
             inputData: {
                 set: function (val) {
                     this.dataInput = val;
                 },
                 get: function () {
                     return this.dataInput
                 }
             },
         },
         directives: {
             Ripple,
         },
         methods: {
             clearData() {
                 this.inputData = {}
             },
             async simpanData(inputData) {
                 const self = this;
                 await axios({
                         method: 'POST',
                         url: '/api/master/bidang/insert',
                         data: inputData,
                         headers: {
                             'Authorization': self.isAuthenticated
                         }
                     })
                     .then(function (response) {
                         self.notification('info', "", response.data.message);
                         self.clearData()
                         self.$emit("loadData", "")

                     }).catch(err => {
                         self.pesan = err.message;
                         self.notification('warning', "Error", err.message);
                     });
             },
             async updataData(inputData) {
                 const self = this;
                 await axios({
                         method: 'POST',
                         url: '/api/master/bidang/update',
                         data: inputData,
                         headers: {
                             'Authorization': self.isAuthenticated
                         }
                     })
                     .then(function (response) {
                         self.notification('info', "", response.data.message);
                         self.clearData()
                         self.$emit("loadData", "")

                     }).catch(err => {
                         self.pesan = err.message;
                         self.notification('warning', "Error", err.message);
                     });
             },

         }

     }
 </script>